<template>
  <div>
    <b-modal
      modal-class="show-modal-notification"
      centered
      hide-footer
      id="show-notification"
      :title="$t('notifications.show_notification')"
    >
      <ul>
        <li>
          <h5>{{ $t("notifications.title") }}</h5>
          <p>{{ page_data.title }}</p>
        </li>
        <li>
          <h5>{{ $t("notifications.body") }}</h5>
          <p class="p-note-body">{{ page_data.body }}</p>
        </li>
        <li>
          <h5>{{ $t("notifications.date") }}</h5>
          <p>{{ $helpers.dateTimeInFormatWihtTime(page_data.created_at) }}</p>
        </li>
      </ul>
      <b-button
        variant="primary"
        class="btn-cancel"
        @click="$bvModal.hide('show-notification')"
      >
        {{ $t("notifications.cancel") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
export default {
  name: "ShowNotification",
  components: {
    BModal,
    BButton,
  },
  props: {
    page_data: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss">
@import "./_ShowModalNotification";
</style>
