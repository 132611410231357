<template>
  <Loading v-if="isLoading" />
  <div v-else class="notifications-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("notifications.notifications") }}</h1>
        <div
          class="d-flex align-items-center"
          v-if="
            globalPermission.notifications &&
            globalPermission.notifications.broadcast
          "
        >
          <router-link
            class="btn btn-primary btn-add"
            :to="{ name: 'add-notification' }"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("notifications.add_notification") }}
          </router-link>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'title'" class="text-nowrap">
            {{ $t("notifications.title") }}
          </span>
          <span v-else-if="props.column.label === 'time'" class="text-nowrap">
            {{ $t("notifications.time") }}
          </span>
          <span v-else-if="props.column.label === 'body'" class="text-nowrap">
            {{ $t("notifications.body") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'title'">
            {{ props.row.name }}
          </span>

          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>

          <span class="text-nowrap" v-if="props.column.field === 'created_at'">
            {{ $helpers.dateTimeInFormatWihtTime(props.row.created_at) }}
          </span>

          <span
            class="text-nowrap body"
            v-else-if="
              props.column.field === 'body' &&
              globalPermission.notifications &&
              globalPermission.notifications.show
            "
          >
            <b-button
              class="btn-view"
              @click="
                viewBody({
                  ...props.row.data,
                  created_at: props.row.created_at,
                })
              "
            >
              {{ $t("notifications.view") }}
            </b-button>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  :to="{
                    name: 'edit-Notification',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteNotification(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <ShowModalNotification :page_data="page_data" />
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";
import ShowModalNotification from "@/components/notifications/ShowModalNotification/ShowModalNotification.vue";

export default {
  name: "NotificationsList",
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    Loading,
    BCard,
    BDropdown,
    BDropdownItem,
    ShowModalNotification,
  },
  data() {
    return {
      page_data: {},
      isLoading: true,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "index",
          sortable: true,
          firstSortType: "desc",
          sortFn: this.sortFn,
        },
        {
          label: "title",
          field: "data.title",
          sortable: false,
        },
        {
          label: "time",
          field: "created_at",
          sortable: false,
        },
        {
          label: "body",
          field: "body",
          sortable: false,
        },
      ],
    };
  },
  created() {
    if (
      this.globalPermission &&
      this.globalPermission.notifications &&
      !this.globalPermission.notifications.show
    ) {
      this.tableHeader = this.tableHeader.filter(
        (item) => "body" !== item.field
      );
    }

    this.getNotificationsList();
  },
  methods: {
    async getNotificationsList() {
      try {
        const response = (
          await this.$http.get("admin/notifications", {
            headers: {
              "X-Page-Size": 1000,
            },
          })
        ).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          el.index = index + 1;
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    viewBody(data) {
      this.$bvModal.show("show-notification");
      this.page_data = data;
    },
    sortFn(x, y, col, rowX, rowY) {
      return x < y ? -1 : x > y ? 1 : 0;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
